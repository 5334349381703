import { useState } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'


const useMailgun = () => {
  const [result, setResult] = useState()
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    setSubmitting(true)
    const data = new FormData(e.target)
    const email = data.get('email')
    const name = data.get('name')
    const values={name, email}

    // first add to mailchimp list ( so we can send via mailchimp for manual emails or via mailgun for auto emails)
    var result = await addToMailchimp(email,{FNAME: name})
    console.log("Result is ", result)
    if (result.result ==='success') {
      // then add to mailgun list via netlify function
      const axios = require("axios")
      const endpoints = {
        contact: "/.netlify/functions/subscribe",
      }
      console.log(JSON.stringify(values))
      const resp  = await axios.post(endpoints.contact, JSON.stringify(values))
      result = resp.data
    }

    setResult(result)
    setSubmitting(false)
  }

  const success = result && result.result === 'success'
  const error = result && result.result !== 'success'
  const canSubmit = !result || error
  const message = result && result.msg
  //console.log("useMailgun: "+ result.msg)

  return {
    handleSubmit,
    canSubmit,
    submitting,
    message,
    success,
    error
  }
}

export default useMailgun

